// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/ListItem.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/ListItem.tsx");
  import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

const ListItem = ({
  label,
  value
}) => {
  return <li className="">
      <p className="text-base text-gray-400">{label}</p>
      <b className="font-semibold">{value}</b>
      <hr className="my-2 border-gray-200" />
    </li>;
};
_c = ListItem;
export default ListItem;
var _c;
$RefreshReg$(_c, "ListItem");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;