// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/pages/PaymentPage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$(),
  _s2 = $RefreshSig$(),
  _s3 = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/pages/PaymentPage.tsx");
  import.meta.hot.lastModified = "1731498231000";
}
// REMIX HMR END

import Button from "../components/Button";
import { Heading } from "../components/Heading";
import ListItem from "../components/ListItem";
import { useConfirmPayment, useHandlePayment } from "../hooks/payment";
import { cn } from "../utils/styles/cn";
import { useNavigate, useSearchParams } from "@remix-run/react";
import { Check } from "lucide-react";
import { useEffect, useState } from "react";
const FALLBACK_CURRENCY = "UAH";
export const meta = () => [{
  title: "Payment page"
}];
const PaymentPage = () => {
  _s();
  const navigate = useNavigate();
  const [isPaid, setIsPaid] = useState(false);
  const [params] = useSearchParams();
  const {
    mutate
  } = useConfirmPayment({
    onSuccess: () => setIsPaid(true)
  });
  const [type, setType] = useState(undefined);
  const [paymentData, setPaymentData] = useState(undefined);
  const [timeRemaining, setTimeRemaining] = useState(0); // Add state for time remaining

  const {
    data,
    refetch,
    isLoading
  } = useHandlePayment({
    variables: params.get("id")
  });
  useEffect(() => {
    if (!data) return;
    setType(data.type);
    switch (data.type) {
      case "p2p":
        {
          setPaymentData({
            amount: data.amount,
            cardNumber: data.cardNumber,
            bank: data.recipientBank
          });
          return;
        }
      case "one-click":
        {
          // Set initial time for countdown (15 minutes and 40 seconds)
          setTimeRemaining(15 * 60 + 0);
          setPaymentData({
            amount: data.amount,
            cardNumber: data.cardNumber,
            bank: data.recipientBank
          });

          // Redirect to payment link
          window.location.assign(data.paymentLink);
          return;
        }
    }
  }, [data, navigate]);
  useEffect(() => {
    let timer;
    if (type === "one-click" && timeRemaining > 0) {
      timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [type, timeRemaining]);
  const handleApprovePayment = () => {
    if (isPaid) {
      return;
    } else {
      mutate(params.get("id"));
    }
  };
  if (isLoading) return <>Loading</>;
  switch (type) {
    case "p2p":
      {
        return <div className={cn("flex size-full flex-col justify-between p-3", "items-center justify-center")}>
          {isPaid ? <PaidView /> : <UnpaidView paymentData={paymentData} />}
          <div className="fixed bottom-4 w-full px-3 md:static md:px-0">
            <Button onClick={handleApprovePayment} color={isPaid ? "success" : "default"}>
              {isPaid ? "Back to home" : "I have paid"}
            </Button>
          </div>
        </div>;
      }
    case "one-click":
      {
        const minutes = Math.floor(timeRemaining / 60);
        const seconds = timeRemaining % 60;
        return <div className={cn("flex flex-col size-full justify-between p-4 bg-gray-900 text-white", "items-center mx-auto")}>
          {/* Logo Section */}
          <div className="flex justify-center mt-8 mb-4">
            <img src="logo.png" alt="Global Solutions Logo" className="h-16" />
          </div>

          <div>
            {/* Payment Request Section */}
            <p className="text-xl font-semibold">Заявка на оплату</p>
            <p className="text-3xl font-bold text-purple-400 mt-4">
              Сума: {paymentData.amount} ₽
            </p>
            <p className="text-gray-400 mt-1">
              Нажмите ниже, чтобы перейти к оплате
            </p>

            {/* Payment Button */}
            <div className="w-full flex flex-row items-center justify-start mt-4">
              <span className="mr-2">К Оплате: </span>
              <img onClick={() => window.location.assign(data.paymentLink)} src={`banks/${paymentData.bank}-button.png`} className="h-[48px] cursor-pointer hover:opacity-80 :focuse:opacity-80" alt={`${paymentData.bank} Bank`} />
            </div>

            {/* Help Section */}
            <div className="mt-8">
              <button className="text-purple-400 hover:underline">
                Нyжна подержка?
              </button>
            </div>
          </div>

          {/* Countdown Timer */}
          <div className="text-center mt-4 text-gray-400">
            Время на оплату{" "}
            <span className="text-purple-400 font-semibold">
              {minutes} мин {seconds < 10 ? `0${seconds}` : seconds} сек
            </span>
          </div>
        </div>;
      }
    default:
      {
        return <></>;
      }
  }
};
_s(PaymentPage, "EknyAV5PIdSehCkzdFHYlkvoWdA=", false, function () {
  return [useNavigate, useSearchParams, useConfirmPayment, useHandlePayment];
});
_c = PaymentPage;
export default PaymentPage;
const PaidView = () => {
  _s2();
  const [params] = useSearchParams();
  return <>
      <Check className="mb-2 w-max rounded-full bg-green-500 p-2 text-white" size={112} />
      <Heading className="mb-4">
        {params.get("amount")} {params.get("currency") ?? FALLBACK_CURRENCY}
      </Heading>
      <p className="rounded-xl bg-green-600/10 p-3 text-center text-green-800 md:mb-6">
        Your balance will be updated shortly. You may close this window.
      </p>
    </>;
};
_s2(PaidView, "KB+Sx1GyQK5Z7eQYQPoF2V7J20Y=", false, function () {
  return [useSearchParams];
});
_c2 = PaidView;
const UnpaidView = ({
  paymentData
}) => {
  _s3();
  const [params] = useSearchParams();
  return <>
      <p className="mb-4 text-center text-gray-600">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dem odio nihil
        quisquam
      </p>
      <ul className="mb-4 w-full rounded-xl bg-white p-4 shadow-lg">
        <ListItem label="Card number" value={params.get("cardNumber") ?? paymentData.cardNumber} />
        <ListItem label="Recipient's bank" value={params.get("bank") ?? paymentData.bank} />
        <ListItem label="Amount" value={`${params.get("amount") ?? paymentData.amount} ${params.get("currency") ?? FALLBACK_CURRENCY} `} />
      </ul>
      <p className="px-1 text-sm text-gray-600 md:mb-6">
        Copy the payment details or simply choose your payment app. Make a
        transfer, return and click the &quot;I have paid&quot;
      </p>
    </>;
};
_s3(UnpaidView, "KB+Sx1GyQK5Z7eQYQPoF2V7J20Y=", false, function () {
  return [useSearchParams];
});
_c3 = UnpaidView;
var _c, _c2, _c3;
$RefreshReg$(_c, "PaymentPage");
$RefreshReg$(_c2, "PaidView");
$RefreshReg$(_c3, "UnpaidView");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;